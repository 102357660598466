import _objectSpread from "@babel/runtime/helpers/esm/objectSpread2";
import * as React from 'react';
export default function useCacheDisplayValue(values) {
  var prevValuesRef = React.useRef(values);
  var mergedValues = React.useMemo(function () {
    // Create value - label map
    var valueLabels = new Map();
    prevValuesRef.current.forEach(function (_ref) {
      var value = _ref.value,
          label = _ref.label;

      if (value !== label) {
        valueLabels.set(value, label);
      }
    });
    var resultValues = values.map(function (item) {
      var cacheLabel = valueLabels.get(item.value);

      if (item.isCacheable && cacheLabel) {
        return _objectSpread(_objectSpread({}, item), {}, {
          label: cacheLabel
        });
      }

      return item;
    });
    prevValuesRef.current = resultValues;
    return resultValues;
  }, [values]);
  return mergedValues;
}