"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _pl_PL = _interopRequireDefault(require("../../date-picker/locale/pl_PL"));

var _default = _pl_PL["default"];
exports["default"] = _default;